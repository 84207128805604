.four_compon {
  margin-top: 20px;
  height: auto;
  width: 100%;
}

.contactbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}

.btnnumbr {
  margin-left: 140px;
}

.maps {
  width: 500px;
  height: 400px;
  border: 2px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 2%;
}

@media only screen and (max-width: 1250px) {
  .maps {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .four_compon {
    margin-top: 20px;
  }

  .contactbox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    
  }
  .call-social-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .black{
    text-align: center;
  }
  .grey{
    margin-left: 9%;
  }
  .ft{
    margin-left: 9%;
  }
  .btnnumbr {
    margin-left: 0;
    margin-top: 20px;
  }

  .maps {
    width: 100%;
    height: 300px;
    margin-top: 10px;
  }

  .text {
    margin-right: 5%;
  }

  .line {
    display: none;
  }

  .foot {
    height: auto;
  }
  .social-buttons{
    margin-right: 2%;
  }
}