.App{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  }