.mainbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  background-color: #ededed;
}

.lines {
  margin-bottom: 2%;
  width: 650px;
  border-bottom: 2px solid #131053;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}

.numberbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.circle-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: #131053;
  font-size: 28px;
  font-weight: bold;
  border: 3px solid #131053;
  background-color: transparent;
  margin: 10px;
}

.description-container {
  width: 30%;
  font-size: 21px;
  color: #131053;
  text-align: left;
  margin: 10px;
  font-weight: bold;
}

.number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: 10px;
}
@media only screen and (max-width: 1200px) {
  .description-container{
  font-size: 17px;
  }
}
@media only screen and (max-width: 880px) {
  .description-container{
  font-size: 15
  px;
  }
}
@media only screen and (max-width: 768px) {
  .mainbox {
    width: 100%;
    height: auto;
  }

  .lines {
    width: 80%;
  }

  .numberbox {
    flex-direction: column;
    align-items: center;
  }

  .circle-number {
    width: 155px;
    height: 100px;
    font-size: 24px;
    margin: 5px;
  }

  .description-container {
    width: 80%;
    font-size: 16px;
    text-align: left;
    margin-left: 10%;
  }

  .number-container {
    width: 80%;
    margin: 5px;
  }
}