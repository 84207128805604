@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.navbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  box-shadow: 0 0 8px rgba(148, 147, 147, 0.3);
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  background-color: rgba(42, 41, 59, 0.289);
  z-index: 9999;
}

.navbox ul {
  display: flex;
  list-style: none;
  margin-left: 5%;
  padding: 0;
  font-size: 17px;
}

.navbox li {
  margin: 0 10px;
}

.navbox li a {
  color: rgba(225, 226, 227, 0.909);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  text-shadow: 1px 2px 3px rgba(12, 19, 99, 0.7);
}

.navbox li a:hover,
.navbox li.current-page a { 
  color: #bf731c;
  cursor: pointer;
}

.menu-icon {
  display: none;
}

@media only screen and (max-width: 768px) {
  .navbox ul {
    display: none;
  }
  
  .menu-icon {
    display: block;
    position: absolute;
    top: 5px;
    right: 5%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .menu-icon svg {
    fill: #d5d4d4;
    transition: fill 0.3s ease-in-out;
  }
  
  .menu-icon:hover svg {
    fill: #ce8d14;
  }
  
  .navbox-expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    position: fixed;
    background-color: rgba(56, 56, 56, 0.976);
    box-shadow: 0 0 10px rgba(148, 147, 147, 0.3);
    z-index: 1000;
  }
  
  .navbox-expanded ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 35%;
    padding: 0;
    list-style: none;
    width: 100%;
  }
  
  .navbox-expanded li {
    margin: 3px ;
  }
  
  .navbox-expanded li a {
    color: #d5d4d4;
    font-size: 23px;
    font-weight: bold;
    text-shadow: 1px 1px 3px rgba(43, 26, 6, 0.5);
    font-family: 'Montserrat', sans-serif;
  }
  
  .navbox-expanded li a:hover {
    color: #ce8d14;
  }
}
