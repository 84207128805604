.modal {
  display: block;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); 
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.648); 
  margin: 6% auto;
  padding: 15px;
  border-radius: 10px;
  width: 65%;
  color: #f7f6f6;
  align-items: center;
}

.close {
  color: #f7f6f6;
  float: right; 
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  z-index: 4;
}

.close:hover,
.close:focus {
  color: #b77f2c; 
  text-decoration: none;
  cursor: pointer;
}
.image-gallery-image {
  height: 380px;
  padding: 20px;
}
.image-gallery-fullscreen-button {
  display: none;}
.image-gallery-play-button {
  display: none;
}