.services { 
  background-image: url('./60a99cfd19ee4589327ae484a728341c.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  }
  
  .rectangle-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: auto;
  background-color: rgba(56, 56, 56, 0.7);
  padding: 20px;
  overflow: hidden;
  }
  
  .rectangle {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 310px;
    height: 150px;
    background-color: white;
    border: 1px solid #191c2b;
    margin: 2%;
    border-radius: 9px;
    gap: 16px;
    overflow: hidden;
      transition: 1s ease-in-out;
  }
  .rectangle__inner {
    position: absolute;
    border-radius: 5px;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: 4px solid #191c2b;
    background-color: rgba(0, 0, 0, 0);
  }
  
  .rectangle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(94, 94, 94, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .rectangle:hover::before {
    opacity: 1;
  }
  
  .text {
    margin-right: 11%;
    color: #212641;
    font: bold 18px/1 'Roboto', sans-serif;
    text-align: center;
  }
  
  h3 {
    color: rgb(192, 148, 82);
  }
  
  .rectangle-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: auto;
    background-color: rgba(56, 56, 56, 0.7);
    padding: 20px;
    overflow: hidden;
  }
  
  @media only screen and (max-width: 768px) {
    .services {
      height: 1600px;
    }
    .rectangle-grid {
      height: 100%;
    }
  }

  .icon{
    margin-left:8%;
    margin-right:-4%;
  }
  .text{
    margin-right: 12%;
    color: #212641;
    font: bold 18px/1 'Roboto', sans-serif;
    text-align: center;
  }
  h3{
    color: rgb(192, 148, 82);
  }
  
  @media only screen and (max-width: 768px) {

    .services { 
      height: 1600px;
    }
    .rectangle-grid{
      height: 100%;
    }

  }