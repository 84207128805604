.text-foot{
  margin-top: 40px;
  font: bold 13px 'Roboto', sans-serif;
  color: #fcfbfb;
  text-align: center;
  height: 75px;
}
.hr{
    border-width: 1.5px;
}
.container{
  background: linear-gradient(to right, #191c2b, #23273a, #24283c);
}