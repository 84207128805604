.scroll-to-top-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 4rem;
    height: 4rem;
    background-color: #e2dfdf;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    opacity: 0;
    border: none;
    visibility: hidden;
    z-index: 10;
  }
  
  .scroll-to-top-button.show {
    opacity: 1;
    visibility: visible;
  }
  
  .scroll-to-top-button svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: #fff;
    margin-top: 3px;
    filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.5));
  }
    
  .scroll-to-top-button:hover {
  background-color: #ce8d14;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.6);
  cursor: pointer;
      }