@font-face {
  font-family: 'PTSerif-Bold';
  src: url('/public/fonts/PTSerif-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Alternates';
  src: url('/public/fonts/ofont.ru_Montserrat Alternates.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;}

  :root {
    --mainbox-height: 800px;
    --box-one-width: 72%;
    --box-one-height: 70%;
    --box-two-width: 46%;
    --box-two-height: 64%;
    --img-width: 32%;
  }
  .mainboxabout {
    height: var(--mainbox-height);
    background: linear-gradient(to right, #191c2b, #23273a, #24283c);
    width: 100%;
    overflow: auto;
    position: relative;
  }
  

  .btn {
    font-family: 'PT Sans', sans-serif;
    width: 244px;
    height: 50px;
    background: none;
    border: 2.8px solid #23273a;
    color: #23273a;
    padding: 0 20px;
    line-height: 50px;
    border-radius: 1px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-overflow: ellipsis;
  }
  .btn:hover {
    background-color: rgba(46, 46, 81, 0.9);
    color: rgb(255, 253, 253);
    transform: scale(0.95);
    cursor: pointer;
    border: 2.8px solid #f7f8f9;
  }
  
  .btn:active {
    transform: scale(0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .btnnbox {
    margin-left: 59%;
    margin-top: 5%;
  }
.box_one,
.box_two {
  position: absolute;
  bottom: 7%;
  right: 0;
  width: var(--box-one-width);
  height: var(--box-one-height);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  border-radius: 2px;
}

.box_one {
  background-color: #ccd1cd;
}


.box_two {
  right: 39%;
  width: var(--box-two-width);
  height: var(--box-two-height);
  background-color: #273741;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.imgg {
  position: absolute;
  bottom: 2%;
  right: 12%;
  width: var(--img-width);
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  display: block;
}
.top{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-left: 50%;
}
.top_text{
  font-family: 'PTSerif-Bold', serif;
  font-size: 25px;
  color: #fbf9f9;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  margin-left: 5%;
}
.lin{
  border-bottom: 1px solid #fbf9f9;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  width: 210px;
}
.main_text{
  margin-left: 54%;
  margin-top: 1%;
  font-family: 'Montserrat-Alternates';
  width: 500px;
  font-size: 19px;
  color: #fbf9f9;
  text-align: right;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
}
.text_about{
  font-family: 'Montserrat-Alternates';
  color: #fbf9f9;
  margin-left: 7%;
  margin-top: 0%;
  width: 75%;
  text-align: justify;
}
.abb{
  font-size: 1em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}
h3{
  font-size: 19px;
  font-family: 'PTSerif-Bold', serif;
  margin-top: 16px;
  margin-bottom: 6px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}

@media (max-width: 1350px){
  .abb{
    font-size: 0.8em; 
  }
}
@media (max-width: 920px){
  .abb{
    font-size: 0.7em;
  }
}
@media (max-width: 768px) { 
  :root {
  --mainbox-height: 100%;
  --box-one-width: 100%;
  --box-one-height: 50%;
  --box-two-width: 100%;
  --box-two-height: 35%;
  --img-width: 100%;
  }
  .box_design {
    display: none;
  }
  .mainboxabout {
  height: auto;
  }
  .box_one {
    position: static;
    width: 100%;
    height: auto;
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  
  .box_two {
  position: static;
  width: 100%;
  height: var(--box-two-height);
  right: 0;
  border-radius: 0;
  box-shadow: none;
  }
  
  .imgg {
  position: static;
  width: var(--img-width);
  margin: auto;
  display: block;
  }
  
  .top {
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  }
  
  .top_text {
  text-align: justify;
  padding: 3%;
  font-size: 20px;
  }
  
  .lin {
  display: none;
  }
  
  .main_text {
  margin-left: 10px;
  margin-right: 10px;
  width: 90%;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  }
  
  .text_about {
  width: 90%;
  padding: 2px;
  margin-bottom: 5%;
  text-align: center;
  }
  
  .abb {
  font-size: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  }
  
  h3 {
  font-size: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
  }
  .btnnbox{
    margin: 20px auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  }