@font-face {
  font-family: 'PTSerif-Bold';
  src: url('/public/fonts/PTSerif-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Alternates';
  src: url('/public/fonts/ofont.ru_Montserrat Alternates.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor:default;
}

.first_compon {
  position: relative;
  height: 100vh;
}

.first_compon .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.btnbox{
  width: 50%;
  max-width: 400px;
  height: 20%;
  margin-top: 34%;
  margin-left: 0;
  background-color: rgba(25, 28, 43, 0.94);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(6, 10, 45, 0.6);
  position: relative;
  z-index: 2;
}



.btns {
  display: block;
  text-align: center;
  font-family: 'PTSerif-Bold', serif;
  width: 100%;
  max-width: 310px;
  height: 51px;
  color: #bdbcbc;
  background-color: rgba(25, 28, 43, 0.0);
  font-size: 1.2em;
  word-wrap: break-word; 
  text-overflow: ellipsis; 
  text-shadow: 3px 3px 5px rgba(28, 27, 27, 0.5);
  position: relative;
  border: 2px solid #bdbcbc;
  border-radius: 2px;
  overflow: hidden;
}
  .btns:hover {
  background-color: rgba(182, 182, 183, 0.9);
  color: black;
  transform: scale(0.95);
  cursor: pointer;
  text-shadow: 1px 1px 3px rgba(28, 27, 27, 0.5);
  }
  .btns:active {
    transform: scale(0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

.box {
  width: 41%;
  height: 260px;
  display: flex;
  background-color: rgba(25, 28, 43, 0.94);
  border-radius: 3px;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  margin-top: 90px;
  margin-left: 59%;
  color: #f9f8f4;
  padding-left: 30px;
  padding-right: 2%;
  padding-bottom: 10px;
  line-height: 1; 
  box-shadow: 0 2px 10px rgba(6, 10, 45, 0.6);
}
  .name{
    font-family: 'PTSerif-Bold', serif;
    font-size: 32px;
    text-shadow: 3px 3px 5px rgba(28, 27, 27, 0.5);
  }
  .description,.description_job,.experience{
    font-family: 'Montserrat-Alternates';
    margin-bottom: 8px;
    font-size: 18px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  .linex{
    border-bottom: 1px solid #fbf9f9;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
    width: 310px;
    margin-left: -90px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  @media only screen and (max-width: 768px) {
    .first_compon {
      flex-direction: column;
      width: 100%;
      height: auto;
    }
    
    .btnbox {
      width: 100%;
      background-color: rgba(25, 28, 43, 0.5);
    }
    
    .box {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
      margin: 0;
      margin-top: 15%;
      padding: 20px;
      background-color: rgba(25, 28, 43, 0.5);
      box-sizing: border-box;
    }
  
    .linex {
      width: 50%;
      margin: 5%;
    }
  
    .btnsoc {
      margin-top: 4%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
    
    .blur {
      display: none;
    }
  }
  




