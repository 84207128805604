.social-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .social-buttons svg {
    width: 40px;
    height: 40px;
  }
  .vk-button,
  .telegram-button,
  .whatsapp-button {

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 40px;
    height: 40px; 
    background-color: transparent;
    margin-left: 12px;
    border: 2px solid #bdbcbc;
    color:#bdbcbc;
    
  }
    .vk-button:hover{
      color:white;
      background-color: #4c75a3;
      border: 2px solid#4c75a3;
      cursor: pointer;
    }
    .telegram-button:hover{
      color:white;
      background-color:#0088cc;
      border: 2px solid #0088cc;
      cursor: pointer;
    }
    .whatsapp-button:hover {
      color:white;
      background-color:#25D366;
      border: 2px solid #25D366;
      cursor: pointer;
    }
  .vk-button:hover img,
  .telegram-button:hover img,
  .whatsapp-button:hover img {
    filter: invert();
  }
  @media (max-width: 768px) { 
    .social-buttons svg {
      width: 38px;
      height: 38px;
    }
    .vk-button,
  .telegram-button,
  .whatsapp-button {
    width: 39px;
    height: 39px; 
  }
}