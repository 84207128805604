
.contact{
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1{
  font: bold 21px/1 'Roboto', sans-serif;
  
}
.black{
  font: bold 15px 'Roboto', sans-serif;
  line-height: 1.5; 
}
.grey{
  color: #9a9999;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px; 
  font-weight: normal;
}
.textе{
  font: bold 18px/1 'Roboto', sans-serif;
  color: #050505;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;

  }
.call-social-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.phone-button {
  background-color: #e2dfdf;
  border: none;
  border-radius: 10px;
  color: #2d2d2d;
  cursor: pointer;
  font: bold 18px/1 'Roboto', sans-serif;
  height: 50px;
  margin: 10px;
  padding: 10px;
  width: 200px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
  text-align: center;
  }
  
  .phone-button:hover {
    background-color: #bcb9b9;
  }
  
  .phone-button:active {
    background-color: #ccc;
    transform: scale(0.9);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
 .phone-button:focus {
    outline: none;
  }
  
.ft{
  display: flex;
  align-items: center;
}
.line {
  border-bottom: 0.5px solid #a8a7a7;
  width: 110px;
  margin-left: 40px;
}
